<template>
	<!-- 账单记录 -->
	<div class="platform-fund-bill">
		<div class="pfb-top">
			<div class="pfb-top-money">
				<div class="pfb-top-money-li">
					<div>收入金额（元）
						<a-tooltip >
							<template #title>
								<div>1、已完成的订单收益</div>
								<div>2、多商户认证费用</div>
								<div>3、多商户交易抽成费用</div>
								<div>4、提现手续费用</div>
							</template>
							<i class="el-icon-info"></i>
						</a-tooltip>
					</div>
					<div class="pfbt-price">
						<span v-if="money">{{money.bank}}</span>
					</div>
				</div>
			</div>
			
			<div class="pfb-search">
				<a-space>
					<a-range-picker v-model:value="search.time" />
					<a-button type="primary" @click="getBillRecord(1,info.limit)">筛选</a-button>
				</a-space>
			</div>
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">账单记录</div>
			<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'类型',dataIndex:'operation_type_show',slots:{customRender:'operation_type_show'}},
				{title:'交易时间',dataIndex:'create_time'},
				{title:'名称',dataIndex:'description'},
				{title:'收支类型',dataIndex:'trade_status_name'},
				{title:'金额(元)',dataIndex:'amount',slots:{customRender:'amount'}},
				{title:'状态',dataIndex:'trade_status_name'},
			]" >
				<template #operation_type_show="{record}">
					<span v-if="record.type == 1">增加可用</span>
					<span v-if="record.type == 2">减少可用</span>
					<span v-if="record.type == 3">增加冻结</span>
					<span v-if="record.type == 4">减少冻结</span>
				</template>
				<template #amount="{record}">
					<span v-if="record.type == 1" style="color: #44aa00;">+ {{record.amount}}</span>
					<span v-if="record.type == 2" style="color: #e50000;">- {{record.amount}}</span>
				</template>
				<template #expandedRowRender="{record}">
					<a-table :pagination="false" :data-source="record.log" row-key="id" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'类型',dataIndex:'operation_type_show',slots:{customRender:'operation_type_show'}},
						{title:'交易时间',dataIndex:'create_time'},
						{title:'名称',dataIndex:'description'},
						{title:'收支类型',dataIndex:'trade_status_name'},
						{title:'金额(元)',dataIndex:'amount',slots:{customRender:'amount'}},
						{title:'状态',dataIndex:'trade_status_name'},
					]" >
						<template #operation_type_show="{record}">
							<span v-if="record.type == 1">增加可用</span>
							<span v-if="record.type == 2">减少可用</span>
							<span v-if="record.type == 3">增加冻结</span>
							<span v-if="record.type == 4">减少冻结</span>
						</template>
						<template #amount="{record}">
							<span v-if="record.type == 1" style="color: #44aa00;">+ {{record.amount}}</span>
							<span v-if="record.type == 2" style="color: #e50000;">- {{record.amount}}</span>
						</template>
					</a-table>
				</template>

			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBillRecord(info.page,e)}"
					@change="(e)=>{getBillRecord(e,info.limit)}"
				/>
			</div>
		</div>
		
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop';
import userModel from '@/api/user';
export default{
	setup(){
		const state = reactive({
			search:{
				time:[],
				order_type:'',
				status:'',
				order_number:'',
				account_id:''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			money:null,
			storeList:[],	//店铺列表
		})
		shopModel.getShopUserDetail(res=>{
			state.shopInfo = res

			shopModel.getShopMoney(res.shop.shop_id,res=>{
				state.money = res
				getBillRecord(1,state.info.limit)
			})
		})

		function getBillRecord(page,limit){
			userModel.getMoneyTrade(page,limit,{user_id:state.money.id,type:1,...state.search},res=>{
				state.info = {limit,...res}
			})
		}

		return{
			...toRefs(state),
			getBillRecord
		}
	}
}
</script>

<style lang="scss">
	.pfb-search{
		padding-left: 20px;
	}
	.pfb-top{
		width: 100%;
		// height:420px;
		background: #fff;
		padding-bottom: 24px;
		
		&-money{
			width:90%;
			height: 120px;
			display: flex;
			padding: 24px;
			border-bottom: 1px solid #ebeef5;
			margin-bottom: 24px;
			
			&-li{
				width: 400px;
				height: 100%;
				
				>div:first-child{
					color: #999;
					font-size: 14px;
					margin-bottom: 8px;
				}
				.pfbt-price{
					span{
						font-size: 28px;
					}
				}
			}
		}
	}
</style>
